import { useMemo } from 'react';
import { getCurrentYear } from '@helpers/date';
import { calculateUserAgreementUrl } from '@helpers/misc';
import { getOriginDomain, getSupportEmail } from '@helpers/url';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { useTypedSelector } from '@hooks/store';
import { FooterAddress } from '@kit/footer/footer-address';
import { ContentDesktop } from './content/desktop';
import { ContentMobile } from './content/mobile';
import { ContentTablet } from './content/tablet';
import { footerWidgetAddressClassName, FooterWidgetStyled } from './styled';
import { FooterWidgetContentProps, FooterWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const FooterWidget: AppFC<FooterWidgetProps> = props => {
  const {
    logoImageSrc
  } = props;
  const domain = getOriginDomain();
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const supportEmail = useMemo(() => getSupportEmail(), []);
  const footerContentProps: FooterWidgetContentProps = useMemo(() => {
    return {
      indexRouteDescriptor: ROUTES.index.getDescriptor(),
      linksProps: [{
        href: calculateUserAgreementUrl(roleType),
        children: 'Пользовательское соглашение'
      }, {
        href: ROUTES.privacyPolicy.index.getUrl(),
        children: 'Политика конфиденциальности'
      }, {
        href: ROUTES.tariffs.index.getUrl(),
        children: 'Тарифы'
      }],
      emailLinkProps: {
        href: `mailto:${supportEmail}`,
        children: `${supportEmail}`
      },
      contactLinkProps: {
        href: ROUTES.contacts.index.getUrl(),
        children: `Контакты`
      },
      copyright: `${getCurrentYear()}`,
      domain,
      logoImageSrc
    };
  }, [domain, logoImageSrc, roleType, supportEmail]);
  return _jsxs(FooterWidgetStyled, {
    children: [_jsx(ContentMobile, {
      ...footerContentProps
    }), _jsx(ContentTablet, {
      ...footerContentProps
    }), _jsx(ContentDesktop, {
      ...footerContentProps
    }), _jsx(FooterAddress, {
      className: footerWidgetAddressClassName
    })]
  });
};