import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { Link, LinkExternal } from '@managers/links';
import { ContentDividerStyled, ContentLeftColStyled, ContentLinkStyled, ContentRightColStyled, ContentStyled, CopyrightStyled, LinksContainerStyled, LinksWrapStyled, LogoLinkStyled, LogoStyled } from './styled';
import { ContentMobileProps } from './types';
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const ContentMobile: AppFCC<ContentMobileProps> = props => {
  const {
    indexRouteDescriptor,
    linksProps,
    emailLinkProps,
    contactLinkProps,
    copyright,
    domain,
    logoImageSrc
  } = props;
  return _jsxs(ContentStyled, {
    children: [_jsxs(CopyrightStyled, {
      children: ["\xA9\xA0", copyright]
    }), _jsx(LogoLinkStyled, {
      routeDescriptor: indexRouteDescriptor,
      children: _jsx(LogoStyled //
      , {
        src: logoImageSrc,
        alt: domain
      })
    }), _jsxs(LinksContainerStyled, {
      children: [_jsx(ContentRightColStyled, {
        className: "mobile-h:float-right",
        children: _jsxs(LinksWrapStyled, {
          children: [_jsx(Link, {
            routeDescriptor: ROUTES.contacts.index.getDescriptor(),
            children: contactLinkProps.children
          }), _jsx(ContentDividerStyled, {
            children: "|"
          }), _jsx(LinkExternal, {
            href: emailLinkProps.href,
            children: emailLinkProps.children
          })]
        })
      }), _jsx(ContentLeftColStyled, {
        children: linksProps.map(({
          href,
          children
        }, index) => {
          return _jsx(ContentLinkStyled //
          , {
            href: href,
            children: children
          }, index);
        })
      })]
    })]
  });
};